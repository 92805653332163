<template>
  <div class="group-items-container shadow-md bg-th-light-blue">
    <!-- Filters -->
    <filter-header
      show-filter
      show-search-filter
      :resource="false"
      :search-filters="filters"
      :apply-margin="false"
      route-base="products"
      class="p-4"
      @search-filter-submit="handleSubmit"
    />

    <div class="list-wrapper shadow-th-light">
      <!-- List -->
      <div class="list-container left-list-container">
        <div class="item-header">
          <el-checkbox
            :checked="
              !!computedList.length &&
              computedList.length === computedSelected.length
            "
            :model-value="isListAllChecked"
            @change="handleAllChange($event)"
          />
          <div class="ml-3 text-th-primary-gray">
            {{
              $t('pages.settings.users.permissions.manager.template.select_all')
            }}
          </div>
        </div>

        <div
          ref="list"
          v-loading="loading"
          class="items-list"
          data-testid="group-items-select-list"
        >
          <dynamic-scroller
            class="scroller"
            :items="computedList"
            :min-item-size="81"
            key-field="id"
            @scroll="onScroll"
          >
            <template #default="{ item, active }">
              <dynamic-scroller-item
                :item="item"
                :active="active"
                :size-dependencies="[item.addresses]"
                :data-index="item.id"
              >
                <list-item
                  :item="item"
                  :handle-select-change="handleSelectChange"
                  :format-address="formatAddress"
                />
              </dynamic-scroller-item>
            </template>
          </dynamic-scroller>
        </div>
      </div>

      <!-- Added list -->
      <div class="list-container">
        <div class="item-header">
          <!-- Selected -->
          <div class="px-2 text-th-primary-gray flex-grow">
            {{ $t('pages.permissions.selected') }}
            ({{ computedSelected.length }})
          </div>

          <!-- Remove all -->
          <el-button
            text
            class="p-0 h-auto"
            :disabled="!computedSelected.length"
            @click="removeAll"
            v-text="$t('components.group_items_select.selected.remove_all')"
          />
        </div>

        <div class="items-list">
          <transition-group name="list" tag="div">
            <div
              v-for="item of computedSelected"
              :key="item.id"
              class="item"
              @click.prevent="removeItem(item.id)"
            >
              <div class="item-content">
                <div class="item-details">
                  <span>{{ item.title_main || '–' }}</span>
                  <div v-if="item.addresses">
                    <div
                      v-for="(address, i) in item.addresses"
                      :key="formatAddress(address) || i"
                      class="address"
                    >
                      {{ formatAddress(address) || '–' }}
                    </div>
                  </div>
                </div>
                <span v-if="item.title_secondary" class="ml-sm">
                  {{ item.title_secondary || '–' }}
                </span>
              </div>

              <!-- Remove item -->
              <el-button
                icon="Delete"
                class="ml-4 el-button--text-icon"
                :title="$t('common.interactions.buttons.remove')"
              />
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import th from '@tillhub/javascript-sdk'
import typeOf from 'just-typeof'
import safeGet from 'just-safe-get'
import safeSet from 'just-safe-set'
import flush from 'just-flush'
import { DynamicScroller, DynamicScrollerItem } from 'vue3-virtual-scroller'

import ListItem from './item'
import { formatAddress } from '@/utils/address'
import FilterHeader from '@/components/filter-header'

export default {
  components: {
    DynamicScroller,
    DynamicScrollerItem,
    ListItem,
    FilterHeader
  },
  props: {
    /**
     * the selected items in the value array should be either objects with all the necessary details - in case of a paginated resource,
     * or UUID strings - in case of resource that doesn't have to be paginated.
     */
    modelValue: {
      type: Array,
      default: () => []
    },
    resourceList: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    },
    resource: {
      type: String,
      required: true
    },
    resourceQuery: {
      type: Object,
      default: () => ({})
    },
    /**
     * In order to have a list of items with a consistant data shape to be render properly, a normaliseKeys function should be provided to map at least the item's "name", i.e. display name, and "number", i.e. display number.
     *
     * Example:
     * ```
     *   normaliseKeys(item) {
     *    return {
     *      ...item,
     *      name: `${item.firstname} ${item.lastname}`,
     *      number: item.staff_number
     *    }
     *  }
     * ```
     */
    normaliseKeys: {
      type: Function,
      default: (item) => item
    },
    /**
     * Toggle visibilty of the search filter.
     */
    showSearchFilter: {
      type: Boolean,
      required: false,
      default: undefined
    },
    /**
     * In case of a paginated resource, this prop should be truthy and value will be an array of items as objects instead of UUIDs.
     * By doing so, the components can list the selected items without fetching the entire resource (to get the details for each selected item).
     */
    fullSelectedObject: {
      type: Boolean,
      required: false,
      default: false
    },
    fuzzySearch: {
      type: Boolean,
      required: false,
      default: false
    },
    resultFilter: {
      type: Function,
      required: false,
      default: (results) => results
    },
    parseSearchQuery: {
      type: Function,
      required: false,
      default: (query) => query
    }
  },
  data() {
    return {
      list: [],
      firstFetchList: [],
      filterTerms: {},
      searchFilters: {},
      next: null,
      loading: false
    }
  },
  computed: {
    uniqueItems() {
      const uniques = new Set()
      const values =
        this.modelValue && this.modelValue.length ? this.modelValue : []
      const valueObjects =
        typeOf(values[0]) === 'object'
          ? values
          : this.resourceList.filter((item) => this.valueIds.includes(item.id))

      return [...valueObjects, ...this.list, ...this.firstFetchList].filter(
        (item) => {
          if (uniques.has(item.id)) return false

          uniques.add(item.id)
          return true
        }
      )
    },
    valueIds() {
      const ids =
        this.fullSelectedObject &&
        typeOf((this.modelValue || [])[0]) === 'object'
          ? (this.modelValue || []).map((item) => item.id)
          : this.modelValue
      return ids || []
    },
    computedList() {
      return this.list
        .map(this.normaliseKeys)
        .map((item) => ({ ...item, checked: this.valueIds.includes(item.id) }))
    },
    computedSelected: {
      get() {
        return this.uniqueItems
          .map(this.normaliseKeys)
          .filter((item) => this.valueIds.includes(item.id))
          .sort(
            (a, b) => this.valueIds.indexOf(a.id) - this.valueIds.indexOf(b.id)
          )
      },
      set(selected) {
        const items = this.fullSelectedObject
          ? selected
          : selected.map((item) => item.id)
        this.$emit('update:modelValue', items)
      }
    },
    isSelectedAllChecked() {
      return !!this.computedSelected.length
    },
    isListAllChecked() {
      if (this.loading) return false
      const ids = this.computedList.map((item) => item.id)
      return (
        this.computedSelected.length &&
        this.computedSelected.length >= this.valueIds.length &&
        ids.every((id) => this.valueIds.includes(id))
      )
    },
    isSearch() {
      return this.fuzzySearch && !!this.searchFilters.q
    },
    translations() {
      return {
        billing: this.$t('common.forms.labels.addresses.types.billing'),
        delivery: this.$t('common.forms.labels.addresses.types.delivery'),
        home: this.$t('common.forms.labels.addresses.types.home'),
        local: this.$t('common.forms.labels.addresses.types.local'),
        work: this.$t('common.forms.labels.addresses.types.work')
      }
    }
  },
  mounted() {
    if (this.resourceList && this.resourceList.length) {
      this.list = [...this.resourceList]
    } else {
      this.fetch()
    }
  },
  methods: {
    handleSelectChange(checked, id) {
      if (checked) {
        this.addItem(id)
      } else {
        this.removeItem(id)
      }
    },
    addItem(id) {
      const item = this.fullSelectedObject
        ? this.computedList.find((item) => item.id === id)
        : { id }

      this.computedSelected = item
        ? [item, ...this.computedSelected]
        : this.computedSelected
    },
    removeItem(id) {
      const temp = this.computedSelected.filter((item) => item.id !== id)
      this.computedSelected = temp
    },
    addAll() {
      this.computedSelected = [...this.computedList, ...this.computedSelected]
    },
    removeAll() {
      this.computedSelected = []
    },
    handleAllChange(nextCheck) {
      if (nextCheck) {
        this.addAll()
      } else {
        this.removeAll()
      }
    },
    removeTag(filterObject) {
      // NOTE: mutating the filters object for the specific tag. Not ideal, but currently there's no other way to remove previous search selection and the query input, so it won't persist to further searches.
      safeSet(filterObject, 'originalData', null)

      this.searchFilters[filterObject.name] = undefined
      this.searchFilters = flush(this.searchFilters)
      this.fetch()
    },
    resetFilters() {
      this.searchFilters = {}
      this.fetch()
    },
    handleSubmit(filterObject) {
      const filters = Object.entries(filterObject).reduce(
        (accFiltersResult, currentFilter) => {
          const [key, valueObj] = currentFilter
          return {
            ...accFiltersResult,
            [key]:
              safeGet(valueObj, 'originalData.queryInput') || valueObj.value
          }
        },
        {}
      )
      this.searchFilters = flush(filters)
      this.fetch()
    },
    async fetch() {
      // Changing the limit in the meantime to solve issues with Klier
      // But needs to be fixed!
      const limit = ['branchesV1', 'branches', 'branchGroups'].includes(
        this.resource
      )
        ? 1400
        : 50
      const inst = th[this.resource]()
      const action = inst.getAll({
        ...this.resourceQuery,
        query: { deleted: false, limit, ...this.searchFilters }
      })

      try {
        this.loading = true
        const { data = [], next } = await action
        const _data = this.handleIncomingData(data)
        this.list = [..._data]
        this.firstFetchList = [...this.firstFetchList, ...this.list]
        this.next = next || null
      } catch (err) {
        this.$logException(err, {
          message: err.message,
          trackError: false
        })
      } finally {
        this.loading = false
      }
    },
    async fetchNext() {
      if (!this.next) return
      try {
        this.loading = true
        const { data = [], next } = await this.next()
        const _data = this.handleIncomingData(data)
        this.list = this.list.concat([..._data])
        this.firstFetchList = [...this.firstFetchList, this.list]
        this.next = next || null
      } catch (err) {
        this.$logException(err, {
          message: err.message,
          trackError: false
        })
      } finally {
        this.loading = false
      }
    },
    formatAddress(address = {}) {
      const formattedAddress = formatAddress(address)
      if (!formattedAddress) return

      const type = this.translations[address.type]
      return [type, formattedAddress]
        .filter((item) => typeOf(item) === 'string')
        .join(': ')
    },
    onScroll(event) {
      if (
        event.target.scrollTop + event.target.offsetHeight >=
        event.target.scrollHeight
      ) {
        this.fetchNext()
      }
    },
    handleIncomingData(data) {
      let _data = this.isSearch ? data.search || data.starts_with : data
      _data = _data.map((item) => item.doc || item)
      return this.resultFilter(_data)
    }
  }
}
</script>

<style scoped>
.group-items-container {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.list-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;
  height: 100%;
}

.list-container {
  background: white;
  height: 100%;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.left-list-container {
  border-right: 3px solid var(--light-blue);
}

.items-list {
  /* height: calc(100vh - 520px); */
  /* min-height: 200px; */
  overflow: auto;
  flex-grow: 1;
}

.scroller {
  height: 100%;
}

.item {
  padding: 1rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--table-border-color);
  line-height: 1.5rem;
  cursor: pointer;
  position: relative;
}

.item:hover {
  background: var(--light-blue);
}

.item:hover::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 20px;
  background-image: linear-gradient(
      to top left,
      var(--light-blue) 50%,
      white 51%
    ),
    linear-gradient(to bottom left, var(--light-blue) 50%, white 51%);
  background-size: 100% 50%;
  background-position: top left, bottom left;
  background-repeat: no-repeat;
}

.left-list-container .item {
  padding: 1rem 2rem 1rem 1rem;
}

.left-list-container .item:hover::before {
  right: 0;
  left: unset;
  background-image: linear-gradient(
      to top right,
      var(--light-blue) 50%,
      white 51%
    ),
    linear-gradient(to bottom right, var(--light-blue) 50%, white 51%);
}

.item.checked {
  color: var(--primary-color);
}

.item-header {
  padding: 1rem;
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  position: relative;
  border-bottom: 3px solid var(--light-blue);
}

.item :deep(.el-checkbox) {
  margin-right: unset;
}

.item-content {
  margin-left: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.item-details {
  display: flex;
  flex-direction: column;
}

.address {
  font-size: 0.8rem;
  color: gray;
  line-height: 1.2;
}

.search-filter :deep(.search-input-container) {
  width: 100%;
}

.center {
  display: flex;
  justify-content: center;
  align-content: center;
}

.ml-sm {
  margin-left: 1rem;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
  opacity: 1;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
