<template>
  <div
    class="item"
    :class="{
      checked: itemSelect.checked,
      'text-th-light-gray': itemSelect.disabled
    }"
    @click.prevent="
      !itemSelect.disabled &&
        handleSelectChange(!itemSelect.checked, itemSelect.id)
    "
  >
    <div class="flex justify-between w-full">
      <div class="flex items-center">
        <el-checkbox
          v-model="itemSelect.checked"
          :checked="!!itemSelect.checked"
          :disabled="itemSelect.disabled"
        />
        <div class="flex flex-col ml-4">
          <span v-text="itemSelect.title_main || '–'" />
          <div v-if="itemSelect.addresses">
            <div
              v-for="(address, i) in itemSelect.addresses"
              :key="formatAddress(address) || i"
              class="text-xs text-th-light-gray"
            >
              {{ formatAddress(address) || '–' }}
            </div>
          </div>
        </div>
        <span v-if="itemSelect.number !== undefined" class="ml-4">
          {{ itemSelect.number || '–' }}
        </span>
      </div>
      <div
        v-if="itemSelect.title_secondary"
        class="flex flex-row items-center ml-4"
      >
        <span>{{ itemSelect.title_secondary || '–' }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    handleSelectChange: {
      type: Function,
      required: true
    },
    formatAddress: {
      type: Function,
      required: true
    }
  },
  computed: {
    itemSelect: {
      get() {
        return this.item
      },
      set(value) {
        this.$emit('update:item', value)
      }
    }
  }
}
</script>

<style scoped>
.item {
  padding: 1em;
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
  line-height: 1.5rem;
  cursor: pointer;
}

.item :deep(.el-checkbox) {
  margin-right: unset;
}
</style>
